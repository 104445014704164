// Theme Colors
$primary: #212121;
$secondary: #80C627;
$secondary-light: #C2EFB0;
$secondary-color-light: #E2F9D9;
$primary-light: #888888;
$graph-primary: #212121;
$graph-secondary: #80C627;
$primary-theme: #80C627;
$primary-theme-dark: #212121;

// Edit, Delete & Warning Colors
$edit-dark: #0EA36D;
$edit-light: #E7FFF7;
$red-color: #ff0000;
$red-color-light: #ffd9d9;
$warning-color: #d2e82f;
$in-progress-color: #F49025;

// Common Colors
$black-color: #000000;
$black-shade: #222222;
$white-color: #ffffff;

// Shade Colors
$shade1: #eeeeee;
$shade2: #cccccc;
$shade3: #707070;
$shade4: #808080;
$shade1-light: #eeeeee8f;
$shade2-light: #ffffff30;
$shade5: #00000040;



// $primary-theme-dark: #212121;
// $primary-theme: #80C627;