@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700&display=swap');
body {
  font-family: 'Public Sans', sans-serif;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex;
}
.jc-ctr {
  justify-content: center;
}
.jc-space-even {
  justify-content: space-evenly;
}
.jc-content-end{
  justify-content: end;
}
.algn-itm-ctr {
  align-items: center;
}
.pl-2 {
  padding-left: 20px;
}
.f-wrap {
  flex-wrap: wrap;
}  
.txt-left {
  text-align: left;
}  
.txt-center {
  text-align: center;
}  
.w-200 {
  width: 200%;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}

.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-1 {
  padding-bottom: 10px;
}
.pb-2 {
  padding-bottom: 20px;
}
.py-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-2 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.py-3 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.px-3{
  padding-left: 30px;
  padding-right: 30px;
}
.pr-1 {
  padding-right: 10px;
}
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px;
}
.ml-1 {
  margin-left: 10px;
}
.m-1 {
  margin: 5px;
}
.mt-0{
  margin-top: 0px;
}
.mb-0{
  margin-bottom: 0px;
} 
.cur-pointer {
  cursor: pointer;
}
.m-a {
  margin: auto !important;
}
.mt-2{
  margin-top: 20px !important;
}
.mt-3{
  margin-top: 30px !important;
}
.mb-2{
  margin-bottom: 20px !important;
}
.mb-3{
  margin-bottom: 30px !important;
}
.text-right {
  text-align: right;
}
.align-center {
  align-items: center;
}
.mb-1 {
  margin-bottom: 10px;
}
.ml-3 {
  margin-left: 30px;
}
.mr-3 {
  margin-right: 30px;
}
.my-4{
  margin-top: 40px;
  margin-bottom: 40px;
}
.mx-4{
  margin-left: 40px;
  margin-right: 40px;
}
.m-0{
  margin: 0px !important;
}
.p-4 {
  padding: 40px !important;
}
.p-2 {
  padding: 20px;
}
.p-3 {
  padding: 30px !important;
}
.pb-3 {
  padding-bottom: 30px !important;
}
td, th{
  padding: 15px 20px !important;
}
.jc-content-b {
  justify-content: space-between;
}
.pt-0 {
  padding-top: 0px !important;
}
.error {
  font-size: 12px;
  color: $red-color;
  margin-bottom: 0;
  opacity: 0.7;
  margin: 0;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $black-color !important;
}
.MuiFormLabel-root.Mui-focused {
  color: $black-color !important;
}

.card-actions {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.spacer {
  flex-grow: 1;
}

 
.cus-table-pagination-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 15px 20px;
  li {
    button {
      padding: 5px 12px !important;
      min-width: auto;
      height: auto;
      background-color: $shade5;
      margin: 0 3px;
      border-radius: 6px;
      &.Mui-selected {
        background-color: $black-color;
        &:hover{
          background-color:  $black-color;
        }
      }
    }
  } 
} 
.admin-text-form {
  margin: 0 auto;
  max-width: 600px;
  background-color: $shade1;
  padding: 20px 50px 50px;
}
.text-field {
  margin-bottom: 24px !important;
}
.image-box {
  max-width: 200px;
  height: 200px;
  line-height: 200px;
  background: $white-color;
  margin: 30px auto;
}
  
.no-shadow {
  box-shadow: none !important;
  -webkit-shadow: none !important;
  -moz-shadow: none !important;   
}
textarea{
  resize: none;
  border-radius: 0px;
  font-family: 'Public Sans', sans-serif;

}
.theme-color {
  label {
    .Mui-checked{
          svg{
            color: $secondary-light !important;
          }
        span {
          svg{
            color: $secondary-light !important;
          }
        }
      }
  }
} 
// .theme-color label span.Mui-checked span svg{
//   color: red !important;
// }

.MuiRadio-colorSecondary.Mui-checked,
.MuiSwitch-colorSecondary.Mui-checked {
  color: $secondary-light !important;
}

/* Scroll Bar Color Change */
::-webkit-scrollbar-thumb {
  background-color: $secondary-light;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: $shade1;
}
::-webkit-scrollbar {
  background-color: $shade1;
  width: 4px;
  height: 4px;
}
/* Scroll Bar Color Change */

:focus-visible {
  outline: 0px;
  box-shadow: none !important;
}
.custom-width {
  .MuiDialog-paperWidthMd, .MuiDialog-paperWidthLg   {
    max-width: 640px;
    min-width: 640px;
  }
}
.edit-productdetails{
  .productDetailsleft {
    width: calc(100% - 400px);
    flex-basis: auto;
  }
  .productDetailsrigh {
    width: 400px;
    flex-basis: auto;
  }
}
.list-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  // grid-auto-rows: minmax(0px, auto);
      p {
        strong {
            display: block;
        }
      } 
}
.customer-order-history{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  grid-auto-rows: minmax(80px, auto);
}

.card-header {
  background:$primary-theme-dark !important;
  padding: 8px 20px !important;
  span{
    font-weight: bold;
    color: $white-color;
    font-size: 16px;
  }
}
.list-points {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0;
  grid-auto-rows: minmax(60px, auto);
  padding: 20px;
  p {
    strong {
        display: block;
    }
  } 
}
.MuiTableCell-stickyHeader {
  top: 0;
  left: 0;
  z-index: auto !important;
  position: sticky;
}
.ml-1 {
  margin-left: 10px;
}

.select-style  {
  display: flex;
    justify-content: flex-end;
  select {
    padding: 3px;
  }
}
.select-style-list{
  &:after {
    border-color: $black-color;
  }
}
.hours-field {
  button {
    padding: 2px;
  }
} 
.position-relative {
  position: relative;
  .remove-icon {
    position: absolute;
    right: -16px;
    top: 28px;
    width: 16px;
    height: 16px;
    svg {
        width: 20px;
    }
  }
}
.badge-status {
  padding: 10px 14px;
  white-space: nowrap;
  border-radius: 50px;
}
.inprogress-badge {
  background: hsl(29deg 88% 59% / 10%);
  color: $in-progress-color;
}
.decline-badge {
  color: $red-color;
  background: rgb(209 26 42 / 10%);
}
.complete-badge {
  color: $edit-dark;
  background: hsl(132deg 44% 41% / 10%);
}
.order-details-page-ui {
  .p-2 {
    height: 100%;
    p{
      margin-top: 5px;
      strong {
          margin-right: 7px;
      }
    }
  }
} 
.cus-table table{
  tr:nth-child(even) {
    background: $shade1 !important;
    td.more{
      background: $shade1 !important;
    }
  }
}


.no-integrated-data {
    padding: 14px;
    border: 1px solid #eeeeee;
    display: block;
    text-align: center;
  img{
    margin: 0 auto;
  }
}

